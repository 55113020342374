import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
  <Layout title="Terms of Service">
    <SEO title="Terms of Service" />
    <p>...</p>
  </Layout>
)

export default TermsPage
